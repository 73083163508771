import React from 'react';
import { URL } from '../utils/url';

function ParentCategory({ category, handleChangeCategory, activeCategory }) {
  return (
    <div
      className="categoryWithName"
      onClick={() => handleChangeCategory({ name: category.name, id: category.id })}>
      <img
        className="filter_category_image"
        src={`${URL}/${category.logo}`}
        alt="category"
        style={{
          backgroundColor: 'white',
          padding: '6px',
          // border: activeCategory.name === category.name ? '2px solid green' : '',
          borderRadius: '50%',
          height: '78px',
          width: '78px',
          objectFit: 'cover',
        }}
      />
      <p
        className={
          activeCategory.name === category.name ? 'nameOfEachCategory_Active' : 'nameOfEachCategory'
        }>
        {category.name}
      </p>
    </div>
  );
}

export default ParentCategory;
