import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { URL } from '../utils/url';

export const getCategories = createAsyncThunk(
  'categories/getCategories',
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetch(`${URL}/categories/get-categories/${id}`);
      if (response.ok) {
        const data = await response.json();
        return data.categories;
      } else {
        throw Error('Error From Server!');
      }
    } catch (err) {
      return rejectWithValue({ message: err.message });
    }
  },
);

const categoriesSlice = createSlice({
  name: 'categories',
  initialState: { categories: [] },
  extraReducers: (builder) => {
    builder.addCase(getCategories.fulfilled, (state, action) => {
      state.categories = action.payload;
    });
  },
});

export default categoriesSlice.reducer;
