import { useNavigate } from 'react-router';
import cartStyles from '../cssFolder/Cart.module.css';
import goBackIcon from '../images/goBackIcon.svg';
import orderDone from '../images/orderDone.svg';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import ProductInCart from './ProductInCart';
import { TbCurrencyDram } from 'react-icons/tb';
import emptyCart from '../images/empty-cart.png';
import ProductInfoModal from './ProductInfoModal';

function Cart() {
  const products = useSelector((state) => state.cart.products);
  const [waiterVisible, setWaiterVisible] = useState(true);
  const navigate = useNavigate();
  const infoMode = useSelector((state) => state.cart.infoMode);
  const totalPrice = products
    .filter((product) => product.status === 'active')
    .reduce((aggr, current) => (aggr += current.count * current.price), 0);

  return products.length ? (
    <div className={waiterVisible ? cartStyles.cart : cartStyles.cartActive}>
      <div className={cartStyles.divForGoBackIconStyle}>
        <img onClick={() => navigate(-1)} src={goBackIcon} alt="back" />
      </div>

      <div className={waiterVisible ? cartStyles.cartProducts : cartStyles.cartProductsActive}>
        <p className={cartStyles.cartName}>Cart</p>
        {products.map((product) => {
          return <ProductInCart key={product.orderId} product={product} />;
        })}
      </div>

      {waiterVisible && (
        <div className={cartStyles.total_price_wrapper}>
          <span>Total:</span>
          <div className={cartStyles.total_price_text_wrapper}>
            {totalPrice}
            <TbCurrencyDram />
          </div>
        </div>
      )}

      <div
        className={
          waiterVisible ? cartStyles.divForOrderButton : cartStyles.divForOrderButtonActive
        }
        onClick={() => {
          setWaiterVisible(false);
          setTimeout(() => {
            navigate('/checkout');
          }, 2000);
        }}>
        <button className={cartStyles.orderButton}>Order</button>
      </div>

      <div className={waiterVisible ? cartStyles.orderDone : cartStyles.orderDoneActive}>
        <img src={orderDone} alt="done" />
      </div>

      <div className={waiterVisible ? cartStyles.orderDoneText : cartStyles.orderDoneTextActive}>
        <p>Your order is accepted</p>
      </div>

      {/* <div
        className={waiterVisible ? cartStyles.orderDoneButton : cartStyles.orderDoneButtonActive}
        onClick={() => navigate('/')}>
        <button>Go to Home</button>
      </div> */}

      {infoMode && <ProductInfoModal id={infoMode} />}
    </div>
  ) : (
    <div className={cartStyles.cart}>
      <div className={cartStyles.divForGoBackIconStyle}>
        <img onClick={() => navigate(-1)} src={goBackIcon} alt="back" />
      </div>
      <div className={cartStyles.empty_cart_wrapper}>
        <div>
          <img src={emptyCart} alt="emptyCart" />
        </div>
        <div className={cartStyles.empty_cart_text}>Your cart is empty</div>
      </div>
    </div>
  );
}

export default Cart;
