import { URL } from './url';

export const cancelPayment = async (paymentIntentId) => {
  try {
    const response = await fetch(`${URL}/stripe/cancel-payment`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({ id: paymentIntentId }),
    });

    const cancelledPayment = await response.json();

    console.log(`${paymentIntentId} payment is canceled`);
    localStorage.removeItem('paymentIntentId');
    return cancelledPayment;
  } catch (err) {
    console.log(err.message);
  }
};
