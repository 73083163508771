import React from 'react';
import addButtonIcon from '../images/addButtonIcon.svg';
import { URL } from '../utils/url.js';
import { useNavigate } from 'react-router';
import '../cssFolder/Menu.css';

function FilteredItems({ getItemsForCategories, categoryID }) {
  const items = getItemsForCategories(categoryID);
  const navigate = useNavigate();

  return items.length ? (
    <div className="divForCategories">
      {items.map((item) => {
        return (
          <div
            key={item.id}
            className="eachCategoryContainer"
            onClick={() => navigate(`/product/${item.id}`)}>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}>
              <img className="ImageForCategoryPizza" src={`${URL}/${item.image}`} alt="" />
              <p className="categoryName">{item.name}</p>
              <p className="categoryPrice">{item.price} amd</p>
            </div>
            <div
              className="divForAddItemWithButton"
              onClick={() => {
                navigate(`/product/${item.id}`);
              }}>
              <button className="itemAddButton">Add</button>
              <img src={addButtonIcon} alt="" />
            </div>
          </div>
        );
      })}
    </div>
  ) : (
    <h2>No products in this category.</h2>
  );
}

export default FilteredItems;
