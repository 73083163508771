import React from 'react';
import { useNavigate } from 'react-router';
import addButtonIcon from '../images/addButtonIcon.svg';
import { URL } from '../utils/url';

function SearchedProducts({ searchedItems }) {
  const navigate = useNavigate();

  return (
    <div className="divForCategories">
      {searchedItems.map((item) => {
        return (
          <div
            key={item.id}
            className="eachCategoryContainer"
            onClick={() => navigate(`/product/${item.id}`)}>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}>
              <img className="ImageForCategoryPizza" src={`${URL}/${item.image}`} alt="product" />
              <p className="categoryName">{item.name}</p>
              <p className="categoryPrice">{item.price} amd</p>
            </div>
            <div
              className="divForAddItemWithButton"
              onClick={() => {
                navigate(`/product/${item.id}`);
              }}>
              <button className="itemAddButton">Add</button>
              <img src={addButtonIcon} alt="add" />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default SearchedProducts;
