import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  products: [],
  deleteMode: null,
  infoMode: null,
};

const areTypesEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i].id !== arr2[i].id || arr1[i].value !== arr2[i].value) {
      return false;
    }
  }

  return true;
};

const areIngredientsEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i].id !== arr2[i].id || arr1[i].name !== arr2[i].name) {
      return false;
    }
  }

  return true;
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    clearCart(state, action) {
      state.products = state.products.map((product) => {
        return {
          ...product,
          status: 'pending',
        };
      });
      state.infoMode = null;
      state.deleteMode = null;
    },
    addProduct(state, action) {
      const newProduct = action.payload;

      if (
        state.products.some(
          (prod) =>
            prod.id === newProduct.id &&
            prod.status !== 'pending' &&
            areIngredientsEqual([...prod.ingredients], newProduct.ingredients) &&
            areTypesEqual([...prod.types], newProduct.types),
        )
      ) {
        state.products = state.products.map((product) => {
          console.log(product.status);
          if (
            product.id === newProduct.id &&
            product.status !== 'pending' &&
            areIngredientsEqual([...product.ingredients], newProduct.ingredients) &&
            areTypesEqual([...product.types], newProduct.types)
          ) {
            product.count++;
          }
          return product;
        });
      } else {
        state.products.push({ ...newProduct, count: 1 });
      }
    },
    removeProduct(state, action) {
      state.products = state.products.filter((product) => product.orderId !== action.payload.id);
    },
    incrementProductCount(state, action) {
      state.products.map((product) => {
        if (product.orderId === action.payload.id) {
          product.count++;
        }
        return product;
      });
    },
    decrementProductCount(state, action) {
      state.products.map((product) => {
        if (product.orderId === action.payload.id) {
          product.count--;
        }
        return product;
      });
    },
    addDeleteMode(state, action) {
      if (state.deleteMode === action.payload.id) {
        state.deleteMode = null;
      } else {
        state.deleteMode = action.payload.id;
      }
    },
    addInfoMode(state, action) {
      state.infoMode = action.payload.id;
    },
    closeInfoModal(state) {
      state.infoMode = null;
    },
  },
});

export default cartSlice.reducer;
export const {
  addProduct,
  incrementProductCount,
  decrementProductCount,
  removeProduct,
  addDeleteMode,
  addInfoMode,
  closeInfoModal,
  clearCart,
} = cartSlice.actions;
