import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import pending from '../images/pending.png';

import {
  incrementProductCount,
  decrementProductCount,
  removeProduct,
  addDeleteMode,
  addInfoMode,
} from '../redux/cartSlice';
import { URL } from '../utils/url';
import decrasing from '../images/decrasing.svg';
import increasing from '../images/increasing.svg';
import trashIcon from '../images/trashIcon.svg';
import cartStyles from '../cssFolder/Cart.module.css';

function ProductInCart({ product }) {
  const dispatch = useDispatch();
  const deleteMode = useSelector((state) => state.cart.deleteMode);

  const handleDecrementProductCount = (e) => {
    e.stopPropagation();
    if (product.count > 1) {
      dispatch(decrementProductCount({ id: product.orderId }));
    }
  };

  const handleIncrementProductCount = (e) => {
    e.stopPropagation();
    dispatch(incrementProductCount({ id: product.orderId }));
  };

  const handleRemoveProduct = (orderId) => {
    dispatch(removeProduct({ id: orderId }));
  };

  const handleAddDeleteMode = () => {
    dispatch(addDeleteMode({ id: product.orderId }));
  };

  const handleAddInfoMode = (e) => {
    e.stopPropagation();
    dispatch(addInfoMode({ id: product.orderId }));
  };

  return (
    <div
      style={{
        position: 'relative',
      }}>
      <div
        key={product.orderId}
        className={
          deleteMode !== product.orderId
            ? cartStyles.productWithDeleting
            : cartStyles.productWithDeletingActive
        }>
        <div
          style={{
            position: 'relative',
            opacity: product.status === 'pending' ? '30%' : '100%',
          }}
          className={cartStyles.divForEachCart}
          onClick={handleAddDeleteMode}>
          <IoIosInformationCircleOutline
            onClick={(e) => handleAddInfoMode(e)}
            size={30}
            style={{
              color: 'green',
              position: 'absolute',
              top: '10px',
              left: '86%',
            }}
          />

          <img
            className={cartStyles.cartImage}
            style={{
              objectFit: 'contain',
            }}
            src={`${URL}/${product.image}`}
            alt="cart"
          />
          <div className={cartStyles.productDetails}>
            <p className={cartStyles.productName}>{product.name}</p>
            <p className={cartStyles.productPrice}>{product.count * product.price} amd</p>
          </div>
          <div className={cartStyles.divForProdcutCount}>
            <div
              className={cartStyles.divForDecrasingButton}
              onClick={(e) => {
                e.stopPropagation();
                if (product.status !== 'pending') {
                  handleDecrementProductCount(e);
                }
                return;
              }}>
              <img className={cartStyles.countDecrasing} src={decrasing} alt="" />
            </div>
            <p className={cartStyles.productCount}>{product.count}</p>
            <div
              className={cartStyles.divForIncreasingButton}
              onClick={(e) => {
                e.stopPropagation();
                if (product.status !== 'pending') {
                  handleIncrementProductCount(e);
                }
                return;
              }}>
              <img className={cartStyles.countIncreasing} src={increasing} alt="" />
            </div>
          </div>
        </div>
        <div
          onClick={() => handleRemoveProduct(product.orderId)}
          className={
            deleteMode !== product.orderId
              ? cartStyles.deleteProduct
              : cartStyles.deleteProductActive
          }>
          <img className={cartStyles.trashImage} src={trashIcon} alt="" />
        </div>
      </div>
      {product.status === 'pending' && (
        <img className={cartStyles.pending_icon} src={pending} alt="pending.." />
      )}
    </div>
  );
}

export default ProductInCart;
