import { useNavigate } from 'react-router';
import notFoundPageStyles from '../cssFolder/NotFoundPage.module.css';
import noInternet from '../images/noInternet.png';
import goBackIcon from '../images/goBackIcon.svg';

function NotFoundPage() {
  const navigate = useNavigate();

  return (
    <div className={notFoundPageStyles.notFoundPage}>
      <div className={notFoundPageStyles.divForGoBackIconStyle}>
        <img onClick={() => navigate(-1)} src={goBackIcon} alt="back" />
      </div>

      <div className={notFoundPageStyles.notFoundAllTogether}>
        <div className={notFoundPageStyles.divForPageNameAndDescription}>
          <p className={notFoundPageStyles.pageNoInternet}>No Internet Connection</p>
          <p className={notFoundPageStyles.pageNoInternetSuggestion}>
            Please check you internet connection and try again
          </p>
        </div>
        <div className={notFoundPageStyles.divForNotFoundPageImage}>
          <img className={notFoundPageStyles.wifi_image} src={noInternet} alt="wifi" />
        </div>
      </div>

      <div className={notFoundPageStyles.divForTryAgainButton}>
        <button className={notFoundPageStyles.tryAgain}>Try Again</button>
      </div>
    </div>
  );
}

export default NotFoundPage;
