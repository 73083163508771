import { BrowserRouter } from 'react-router-dom';
import { Route, Routes } from 'react-router';
import CategoryOpen from './components/CategoryOpen';
import CallWaiter from './components/CallWaiter';
import Cart from './components/Cart';
import RestDescription from './components/RestDescription';
import NotFoundPage from './components/NotFoundPage';
import PaymentDone from './components/PaymentDone';
import Checkout from './components/Checkout';
import PaymentCard from './components/PaymentCard';
import Layout from './components/Layout';
import Menu from './components/Menu';
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/:id/:tableID" element={<Menu />} />
          <Route path="/rest-description" element={<RestDescription />} />
          <Route path="/product/:productID" element={<CategoryOpen />} />
          <Route path="/call-waiter" element={<CallWaiter />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/checkout/payment-with-card" element={<PaymentCard />} />
          <Route path="/checkout/payment-done" element={<PaymentDone />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
