import React, { useEffect } from 'react';
import { Outlet, useParams } from 'react-router';
import Footer from './Footer';
import { useDispatch } from 'react-redux';
import { getRestInfo } from '../redux/restaurantInfoSlice';
import { cancelPayment } from '../utils/helpers';

function Layout() {
  const dispatch = useDispatch();
  const { id, tableID } = useParams();
  const restID = localStorage.getItem('restID');
  const tableIDLocal = localStorage.getItem('tableID');
  const paymentIntentId = localStorage.getItem('paymentIntentId');

  paymentIntentId && cancelPayment(paymentIntentId);

  useEffect(() => {
    if (id) {
      dispatch(getRestInfo({ id, tableID }));
      localStorage.setItem('activePage', `/${id}/${tableID}`);
      localStorage.setItem('tableID', tableID);
      localStorage.setItem('restID', id);
    } else {
      if (restID) {
        dispatch(getRestInfo({ id: restID, tableID: tableIDLocal }));
        localStorage.setItem('restID', restID);
        localStorage.setItem('tableID', tableIDLocal);
      }
    }
  }, [dispatch, id, restID, tableID, tableIDLocal]);

  return (
    <>
      <Outlet />
      <Footer />
    </>
  );
}

export default Layout;
