// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductInfoModal_container__T1lWN {
  width: 100vw;
  height: 100vh;
  background: transparent;
  position: absolute;
  display: flex;
  justify-content: center;
}

.ProductInfoModal_wrapper__7YAFf {
  position: relative;
  margin-top: 40%;
  padding: 30px 10px 10px 10px;
  color: black;
  width: 80%;
  min-height: 20%;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.13);
  background: linear-gradient(
    to right bottom,
    rgba(255, 255, 255, 50%),
    rgba(255, 255, 255, 50%),
    rgba(255, 255, 255, 50%),
    rgba(255, 255, 255, 50%),
    rgba(255, 255, 255, 50%)
  );
}

.ProductInfoModal_closeBtn__q\\+imW {
  position: absolute;
  top: 0px;
  left: 90%;
}

.ProductInfoModal_wrapper_for_list__z87M0 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ProductInfoModal_lists_wrapper__EcQXu {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/cssFolder/ProductInfoModal.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,4BAA4B;EAC5B,YAAY;EACZ,UAAU;EACV,eAAe;EACf,wBAAmB;EAAnB,mBAAmB;EACnB,mBAAmB;EACnB,yCAAyC;EACzC,2BAA2B;EAC3B,mCAAmC;EACnC,2CAA2C;EAC3C;;;;;;;GAOC;AACH;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX","sourcesContent":[".container {\n  width: 100vw;\n  height: 100vh;\n  background: transparent;\n  position: absolute;\n  display: flex;\n  justify-content: center;\n}\n\n.wrapper {\n  position: relative;\n  margin-top: 40%;\n  padding: 30px 10px 10px 10px;\n  color: black;\n  width: 80%;\n  min-height: 20%;\n  height: fit-content;\n  border-radius: 16px;\n  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);\n  backdrop-filter: blur(10px);\n  -webkit-backdrop-filter: blur(10px);\n  border: 1px solid rgba(255, 255, 255, 0.13);\n  background: linear-gradient(\n    to right bottom,\n    rgba(255, 255, 255, 50%),\n    rgba(255, 255, 255, 50%),\n    rgba(255, 255, 255, 50%),\n    rgba(255, 255, 255, 50%),\n    rgba(255, 255, 255, 50%)\n  );\n}\n\n.closeBtn {\n  position: absolute;\n  top: 0px;\n  left: 90%;\n}\n\n.wrapper_for_list {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.lists_wrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ProductInfoModal_container__T1lWN`,
	"wrapper": `ProductInfoModal_wrapper__7YAFf`,
	"closeBtn": `ProductInfoModal_closeBtn__q+imW`,
	"wrapper_for_list": `ProductInfoModal_wrapper_for_list__z87M0`,
	"lists_wrapper": `ProductInfoModal_lists_wrapper__EcQXu`
};
export default ___CSS_LOADER_EXPORT___;
