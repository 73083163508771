import { useNavigate } from 'react-router';
import restDescriptionStyles from '../cssFolder/RestDescription.module.css';
import goBackIcon from '../images/goBackIcon.svg';
import descriptionImage from '../images/descriptionImage.png';
import { useSelector } from 'react-redux';

function RestDescription() {
  const navigate = useNavigate();
  const restInfo = useSelector((state) => state.restaurantInfo.restaurantInfo);

  return (
    <div className={restDescriptionStyles.restDescription}>
      <div className={restDescriptionStyles.divForGoBackIconStyle}>
        <img onClick={() => navigate(-1)} src={goBackIcon} alt="back" />
      </div>

      <div>
        <div className={restDescriptionStyles.divForPageNameAndDescription}>
          <p className={restDescriptionStyles.pageName}>About Restaurant</p>
          <div className={restDescriptionStyles.divForRestDescriptionText}>
            <span className={restDescriptionStyles.spanForShape}></span>
            <img style={{ borderRadius: '30px' }} src={descriptionImage} alt="desc" />
            <p className={restDescriptionStyles.restDescriptionText}>{restInfo?.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RestDescription;
