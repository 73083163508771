import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { URL } from '../utils/url.js';

export const getRestInfo = createAsyncThunk(
  'restaurant_info/getRestIfo',
  async ({ id, tableID }, { rejectWithValue }) => {
    try {
      const response = await fetch(`${URL}/restaurants/getRestInfo/${id}`);
      if (response.ok) {
        const data = await response.json();
        return { ...data.restaurant, tableID };
      } else {
        throw Error('Error from Server!');
      }
    } catch (err) {
      return rejectWithValue({ message: err.message });
    }
  },
);

const restaurantInfoSlice = createSlice({
  name: 'restaurant_info',
  initialState: { restaurantInfo: {} },
  extraReducers: (builder) => {
    builder.addCase(getRestInfo.fulfilled, (state, action) => {
      state.restaurantInfo = action.payload;
    });
  },
});

export default restaurantInfoSlice.reducer;
