import { useState } from 'react';
import callWaiterStyles from '../cssFolder/CallWaiter.module.css';
import bigCallWaiterIcon from '../images/bigCallWaiterIcon.svg';
import goBackIcon from '../images/goBackIcon.svg';
import waiterComing from '../images/waiterComing.svg';
import { useNavigate } from 'react-router-dom';

function CallWaiter() {
  const [waiterVisible, setWaiterVisible] = useState(true);
  const navigate = useNavigate();

  return (
    <div className={callWaiterStyles.callWaiter}>
      <div className={callWaiterStyles.divForGoBackIconStyle}>
        <img onClick={() => navigate(-1)} src={goBackIcon} alt="" />
      </div>
      <div className={callWaiterStyles.callWaiterDetails}>
        <div
          className={
            waiterVisible === true
              ? callWaiterStyles.divForNameAndSuggestion
              : callWaiterStyles.divForNameAndSuggestionActive
          }>
          <p className={callWaiterStyles.callWaiterName}>Call the waiter</p>
          <p className={callWaiterStyles.callWaiterSuggestion}>Just press the button</p>
        </div>

        <div
          className={
            waiterVisible === true
              ? callWaiterStyles.callWaiterCircle
              : callWaiterStyles.callWaiterCircleActive
          }
          onClick={() => {
            setWaiterVisible(false);
          }}>
          <img src={bigCallWaiterIcon} alt="" />
          <p className={callWaiterStyles.nameUnderCircle}>Call</p>
        </div>
      </div>

      <div
        className={
          waiterVisible === true
            ? callWaiterStyles.animationOfWaiterComing
            : callWaiterStyles.animationOfWaiterComingActive
        }>
        <img className={callWaiterStyles.waiterComingIcon} src={waiterComing} alt="" />
      </div>

      <div
        className={
          waiterVisible === true
            ? callWaiterStyles.animationOfWaiterComingText
            : callWaiterStyles.animationOfWaiterComingTextActive
        }>
        <p>The waiter is comming</p>
      </div>
    </div>
  );
}

export default CallWaiter;
