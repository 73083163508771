import React from 'react';
import styles from '../cssFolder/ProductInfoModal.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { closeInfoModal } from '../redux/cartSlice';
import { IoIosClose } from 'react-icons/io';

function ProductInfoModal({ id }) {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.cart.products);
  const currentProduct = products.find((product) => product.orderId === id);
  const handleCloseModal = () => dispatch(closeInfoModal());

  return (
    <div className={styles.container} onClick={handleCloseModal}>
      <div className={styles.wrapper} onClick={(e) => e.stopPropagation()}>
        <div className={styles.lists_wrapper}>
          <div className={styles.wrapper_for_list}>
            <span>Ingredients</span>
            <ul>
              {currentProduct.ingredients.map((ingredient) => {
                return <li key={ingredient.id}>{ingredient.name}</li>;
              })}
            </ul>
          </div>
          <div className={styles.wrapper_for_list}>
            <span>Types</span>
            <ul>
              {currentProduct.types.map((type) => {
                return <li key={type.id}>{type.value}</li>;
              })}
            </ul>
          </div>
        </div>
        <IoIosClose onClick={handleCloseModal} className={styles.closeBtn} size={32} />
      </div>
    </div>
  );
}

export default ProductInfoModal;
