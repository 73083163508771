import { useNavigate } from 'react-router';
import paymentDoneStyles from '../cssFolder/PaymentDone.module.css';
import goBackIcon from '../images/goBackIcon.svg';
import paymentDoneIcon from '../images/paymentDoneIcon.svg';

function PaymentDone() {
  const navigate = useNavigate();

  return (
    <div className={paymentDoneStyles.paymentDone}>
      <div className={paymentDoneStyles.divForGoBackIconStyle}>
        <img onClick={() => navigate(-1)} src={goBackIcon} alt="" />
      </div>

      <div className={paymentDoneStyles.paymentDoneContainer}>
        <div className={paymentDoneStyles.paymentDoneIcon}>
          <img src={paymentDoneIcon} alt="" />
        </div>
        <div className={paymentDoneStyles.paymentDoneHeaderText}>
          <p>Your Payment has been successfully done</p>
        </div>
        <div className={paymentDoneStyles.totalItemsWithPrice}>
          <div className={paymentDoneStyles.eachItemWithPrice}>
            <p className={paymentDoneStyles.itemName}>Pizza Margarita</p>
            <p className={paymentDoneStyles.itemPrice}>3200 amd</p>
          </div>
          <div className={paymentDoneStyles.eachItemWithPrice}>
            <p className={paymentDoneStyles.itemName}>Coca Cola</p>
            <p className={paymentDoneStyles.itemPrice}>600 amd</p>
          </div>
          <div className={paymentDoneStyles.eachItemWithPrice}>
            <p className={paymentDoneStyles.itemName}>Greece Salad</p>
            <p className={paymentDoneStyles.itemPrice}>1900 amd</p>
          </div>
        </div>
        <hr className={paymentDoneStyles.grayLine} />
        <div className={paymentDoneStyles.totalDiv}>
          <p className={paymentDoneStyles.totalText}>Total</p>
          <p className={paymentDoneStyles.totalPrice}>6270 amd</p>
        </div>
      </div>
    </div>
  );
}

export default PaymentDone;
