import React from 'react';
import callWaiterIcon from '../images/callWaiterIcon.svg';
import cartIcon from '../images/cartIcon.svg';
import paymenticon from '../images/paymentIcon.svg';
import menuIcon from '../images/menuIcon.svg';
import infoIcon from '../images/infoIcon.svg';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

function Footer() {
  const navigate = useNavigate();
  const activePage = localStorage.getItem('activePage');
  const tableID = localStorage.getItem('tableID');
  const isLoading = useSelector((state) => state.items.isLoading);
  const restInfo = useSelector((state) => state.restaurantInfo.restaurantInfo);
  const { id } = restInfo;

  const handleChangeActivePage = (page) => {
    if (page !== activePage) {
      localStorage.setItem('activePage', page);
      navigate(page);
    }
  };

  const getClassNameForImage = (pathName) => {
    if (activePage === pathName) {
      return 'imgAct image';
    } else {
      return 'image';
    }
  };

  const getClassNameForText = (pathName) => {
    if (activePage === pathName) {
      return 'navLinksActive';
    } else {
      return 'navLinks';
    }
  };

  return (
    <div
      style={{
        opacity: isLoading ? 0 : 1,
      }}
      className="footer">
      <div className="divForFooter">
        <div
          className="footerIconsWithNames"
          onClick={() => handleChangeActivePage('/rest-description')}>
          <img className={getClassNameForImage('/rest-description')} src={infoIcon} alt="" />
          <span className={getClassNameForText('/rest-description')}>Info</span>
        </div>
        <div
          className="footerIconsWithNames"
          onClick={() => handleChangeActivePage('/call-waiter')}>
          <img className={getClassNameForImage('/call-waiter')} src={callWaiterIcon} alt="waiter" />
          <span className={getClassNameForText('/call-waiter')}>Waiter</span>
        </div>
        <div
          className="footerMenuWithName"
          onClick={() => handleChangeActivePage(`/${id}/${tableID}`)}>
          <img className={getClassNameForImage(`/${id}/${tableID}`)} src={menuIcon} alt="menu" />
          <span className={getClassNameForText(`/${id}/${tableID}`)}>Menu</span>
        </div>
        <div className="footerIconsWithNames" onClick={() => handleChangeActivePage('/cart')}>
          <img className={getClassNameForImage('/cart')} src={cartIcon} alt="cart" />
          <span className={getClassNameForText('/cart')}>Cart</span>
        </div>
        <div className="footerIconsWithNames" onClick={() => handleChangeActivePage('/checkout')}>
          <img className={getClassNameForImage('/checkout')} src={paymenticon} alt="bill" />
          <span className={getClassNameForText('/checkout')}>Bill</span>
        </div>
      </div>
    </div>
  );
}

export default Footer;
