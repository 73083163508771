import { CircularProgress, Stack } from '@mui/material';

function Loading() {
  return (
    <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
      <CircularProgress color="success" />
    </Stack>
  );
}

export default Loading;
