import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { URL } from '../utils/url';

export const getSearchedItems = createAsyncThunk(
  'items/getSearchedItems',
  async ({ id, searchText }, { rejectWithValue }) => {
    try {
      const response = await fetch(`${URL}/items/getSearchedItems/${id}/${searchText}`);
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        return data.items;
      } else {
        throw Error(response.statusText);
      }
    } catch (err) {
      return rejectWithValue({ message: err.message });
    }
  },
);

export const getItems = createAsyncThunk(
  'items/getItems',
  async ({ id, category }, { rejectWithValue }) => {
    try {
      const response = await fetch(`${URL}/items/getItemsForTemplate/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'Application/json',
        },
        body: JSON.stringify({ category }),
      });
      if (response.ok) {
        const data = await response.json();
        return data.items;
      } else {
        console.log(response.message);
        throw Error('Error From Server!');
      }
    } catch (err) {
      return rejectWithValue({ message: err.message });
    }
  },
);

const initialState = {
  items: [],
  searchedItems: [],
  isLoading: false,
  message: '',
};

const itemsSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {
    backToAllProducts(state) {
      state.searchedItems = [];
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getItems.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getItems.fulfilled, (state, action) => {
        state.items = action.payload;
        state.isLoading = false;
      })
      .addCase(getItems.rejected, (state, action) => {
        state.message = action.payload.message;
        state.isLoading = false;
      })
      .addCase(getSearchedItems.fulfilled, (state, action) => {
        if (action.payload.length) {
          state.searchedItems = action.payload;
        } else {
          state.message = "Сouldn't find products with this name";
        }
      });
  },
});

export const { backToAllProducts } = itemsSlice.actions;
export default itemsSlice.reducer;
