import { useNavigate } from 'react-router';
import paymentCardStyles from '../cssFolder/PaymentCard.module.css';
import goBackIcon from '../images/goBackIcon.svg';
import masterCard from '../images/masterCard.svg';
import visaCard from '../images/visaCard.svg';
import { useState } from 'react';

function PaymentCard() {
  const [isInputFocused, setInputFocused] = useState(false);
  const navigate = useNavigate();

  const [cardInfo, setCardInfo] = useState({
    cardNumber: '',
    cardHolderName: '',
    expireDate: '',
    cvv: '',
  });

  const formatCreditCardNumber = (input) => {
    const cleanedInput = input.replace(/\D/g, '');

    let formattedInput = '';
    for (let i = 0; i < cleanedInput.length; i++) {
      if (i > 0 && i % 4 === 0) {
        formattedInput += ' ';
      }
      formattedInput += cleanedInput[i];
    }

    return formattedInput;
  };

  const formatCardHolderName = (input) => {
    const cleanedCardHolderName = input.replace(/[^a-zA-Z\s]/g, '');
    return cleanedCardHolderName;
  };

  const formatExpireDate = (input) => {
    const cleanedInputExpDate = input.replace(/\D/g, '');

    let formattedExpireDate = '';
    for (let i = 0; i < cleanedInputExpDate.length; i++) {
      if (i > 0 && i % 2 === 0) {
        formattedExpireDate += '/';
      }
      formattedExpireDate += cleanedInputExpDate[i];
    }

    if (
      formattedExpireDate.slice(0, 2) < 1 ||
      formattedExpireDate.slice(0, 2) > 12 ||
      formattedExpireDate.slice(3) < new Date().getFullYear().toString().slice(2)
    ) {
      setErrors({ ...errors, expireDate: 'Invalid month or year' });
    } else {
      setErrors({ ...errors, expireDate: '' });
    }
    return formattedExpireDate;
  };

  const formatCvv = (input) => {
    const cleanedInputCvv = input.replace(/\D/g, '');
    let formattedCvv = '';
    for (let i = 0; i < cleanedInputCvv.length; i++) {
      formattedCvv += cleanedInputCvv[i];
    }
    return formattedCvv;
  };

  const [errors, setErrors] = useState({});

  const validateCardNumber = (e) => {
    if (cardInfo.cardNumber === '') {
      return 'Please fill in card number input';
    } else if (cardInfo.cardNumber.length < 19) {
      return 'Card number must have 19 digits';
    }
    return '';
  };

  const validateCardHolderName = (e) => {
    if (cardInfo.cardHolderName === '') {
      return 'Please fill in card holder name input';
    }
    return '';
  };

  const validateExpireDate = (e) => {
    if (cardInfo.expireDate === '') {
      return 'Please fill in expire date input';
    } else if (
      cardInfo.expireDate.slice(0, 2) < 1 ||
      cardInfo.expireDate.slice(0, 2) > 12 ||
      cardInfo.expireDate.slice(3) < new Date().getFullYear().toString().slice(2)
    ) {
      return 'Invalid month or year';
    }
    return '';
  };

  const validateCvv = (e) => {
    if (cardInfo.cvv === '') {
      return 'Please write only numbers';
    } else if (e.length < 3) {
      return 'CVV must have 3 characters';
    }
    return '';
  };

  const handleInputChange = (field, value) => {
    const newValues = { ...cardInfo, [field]: value };
    if (field === 'cardNumber') {
      newValues.cardNumber = formatCreditCardNumber(value);
    }

    if (field === 'cardHolderName') {
      newValues.cardHolderName = formatCardHolderName(value);
    }

    if (field === 'expireDate') {
      newValues.expireDate = formatExpireDate(value);
    }
    if (field === 'cvv') {
      newValues.cvv = formatCvv(value);
    }
    setCardInfo(newValues);
    const newErrors = {
      ...errors,
      [field]: validateField(field, value),
    };
    setErrors(newErrors);
  };

  const validateField = (field, value) => {
    switch (field) {
      case 'cardNumber':
        return validateCardNumber(value);
      case 'cardHolderName':
        return validateCardHolderName(value);
      case 'expireDate':
        return validateExpireDate(value);
      case 'cvv':
        return validateCvv(value);
      default:
        return '';
    }
  };

  // Checking is inputs filled for paying
  const payNow = () => {
    const validationErrors = {
      cardNumber: validateCardNumber('cardNumber', cardInfo.cardNumber),
      cardHolderName: validateCardHolderName('cardHolderName', cardInfo.cardHolderName),
      expireDate: validateExpireDate('expireDate', cardInfo.expireDate),
      cvv: validateCvv('cvv', cardInfo.cvv),
    };

    if (
      Object.values(validationErrors).some((error) => error !== '') ||
      cardInfo.cardNumber.length < 19 ||
      cardInfo.cvv.length < 3
    ) {
      setErrors(validationErrors);
      return;
    } else {
      navigate('/checkout/payment-done');
    }
  };

  return (
    <div className={paymentCardStyles.paymentWithCard}>
      <div className={paymentCardStyles.divForGoBackIconStyle}>
        <img onClick={() => navigate(-1)} src={goBackIcon} alt="" />
      </div>

      <div className={paymentCardStyles.paymentWithCardDetails}>
        <p className={paymentCardStyles.paymentWithCardName}>Payment</p>
        <div className={paymentCardStyles.flipCardDiv}>
          <div
            onFocus={() => setInputFocused(true)}
            onBlur={() => setInputFocused(false)}
            onClick={() => setInputFocused(!isInputFocused)}
            className={
              isInputFocused === false
                ? paymentCardStyles.informationOnCard
                : paymentCardStyles.informationOnCardFlipped
            }>
            <div className={paymentCardStyles.flipCardFront}>
              <img
                style={{ width: '53px', height: '33px', objectFit: 'contain' }}
                src={
                  cardInfo.cardNumber && cardInfo.cardNumber.startsWith(5) ? masterCard : visaCard
                }
                alt=""
              />
              <p className={paymentCardStyles.cardNumbers}>
                {!cardInfo.cardNumber ? '**** **** **** ****' : cardInfo.cardNumber}
              </p>
              <div className={paymentCardStyles.infoDetailsOnCard}>
                <p className={paymentCardStyles.cardOwnerNameSurname}>
                  {!cardInfo.cardHolderName ? 'NAME SURNAME' : cardInfo.cardHolderName}
                </p>
                <div className={paymentCardStyles.validTru}>
                  <p className={paymentCardStyles.validTruText}>VALID TRU</p>
                  <p className={paymentCardStyles.validTruDate}>
                    {!cardInfo.expireDate ? 'MM/YY' : cardInfo.expireDate}
                  </p>
                </div>
              </div>
            </div>
            <div className={paymentCardStyles.flipCardBack}>
              <div className={paymentCardStyles.blackLineOnBackSide}></div>
              <div className={paymentCardStyles.cardSecretLine}>
                <p>{!cardInfo.cvv ? '***' : cardInfo.cvv}</p>
              </div>
            </div>
          </div>
        </div>
        <div className={paymentCardStyles.paymentCardInputs}>
          <label className={paymentCardStyles.cardLabel}>Card Number</label>
          <input
            className={paymentCardStyles.cardInput}
            type="text"
            placeholder="**** **** **** ****"
            maxLength={19}
            value={cardInfo.cardNumber}
            onChange={(e) => handleInputChange('cardNumber', e.target.value)}
          />
          {errors.cardNumber && cardInfo.cardNumber.length !== 19 && (
            <p
              style={{
                color: '#FF0000',
                fontSize: 10,
                fontFamily: 'Inter',
                fontWeight: '400',
                wordWrap: 'break-word',
                textIndent: 7,
              }}>
              {errors.cardNumber}
            </p>
          )}
        </div>
        <div className={paymentCardStyles.paymentCardInputs}>
          <label className={paymentCardStyles.cardLabel}>Cardholder Name</label>
          <input
            className={paymentCardStyles.cardInput}
            type="text"
            placeholder="NAME SURNAME"
            value={cardInfo.cardHolderName}
            onChange={(e) => handleInputChange('cardHolderName', e.target.value)}
          />
          {errors.cardHolderName && (
            <p
              style={{
                color: '#FF0000',
                fontSize: 10,
                fontFamily: 'Inter',
                fontWeight: '400',
                wordWrap: 'break-word',
                textIndent: 7,
              }}>
              {errors.cardHolderName}
            </p>
          )}
        </div>
        <div className={paymentCardStyles.paymentCardTwoSmallInputs}>
          <div className={paymentCardStyles.paymentCardInputs}>
            <label className={paymentCardStyles.smallCardLabel}>Expire Date</label>
            <input
              className={paymentCardStyles.smallCardInput}
              type="text"
              placeholder="MM/YY"
              maxLength={5}
              value={cardInfo.expireDate}
              onChange={(e) => handleInputChange('expireDate', e.target.value)}
            />
            {errors.expireDate && (
              <p
                style={{
                  color: '#FF0000',
                  fontSize: 10,
                  fontFamily: 'Inter',
                  fontWeight: '400',
                  wordWrap: 'break-word',
                  textIndent: 7,
                }}>
                {errors.expireDate}
              </p>
            )}
          </div>
          <div className={paymentCardStyles.paymentCardInputs}>
            <label className={paymentCardStyles.smallCardLabel}>CVV</label>
            <input
              className={paymentCardStyles.smallCardInput}
              type="text"
              maxLength={3}
              placeholder="***"
              value={cardInfo.cvv}
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
              onChange={(e) => handleInputChange('cvv', e.target.value)}
            />
            {errors.cvv && (
              <p
                style={{
                  color: '#FF0000',
                  fontSize: 10,
                  fontFamily: 'Inter',
                  fontWeight: '400',
                  wordWrap: 'break-word',
                  textIndent: 7,
                }}>
                {errors.cvv}
              </p>
            )}
          </div>
        </div>
        <div
          className={paymentCardStyles.payNowButton}
          onClick={() => {
            payNow();
          }}>
          <button>Pay now</button>
        </div>
      </div>
    </div>
  );
}

export default PaymentCard;
