import React, { useState } from 'react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import styles from '../cssFolder/Checkout.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { clearCart } from '../redux/cartSlice';
import { v4 as uuidv4 } from 'uuid';
import { URL } from '../utils/url';
import moment from 'moment';

function FormStripe({ setWaiterVisible, amount }) {
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState(false);
  const [message, setMessage] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const products = useSelector((state) => state.cart.products);
  const restInfo = useSelector((state) => state.restaurantInfo.restaurantInfo);

  console.log(products);

  const handleOrder = () => {
    const currentDate = moment();
    const formattedDate = currentDate.format('DD-MM-YYYY HH:mm:ss');

    const order = {
      orderDate: formattedDate,
      amount,
      body: JSON.stringify(products),
      id: uuidv4(),
      waiterName: 'John Doe',
      status: 'new',
      tableNumber: restInfo.tableID,
      restaurantID: restInfo.id,
    };

    fetch(`${URL}/orders/add-order`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify(order),
    })
      .then((res) => res.json())
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  const handlePay = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: '',
      },
      redirect: 'if_required',
    });

    if (error) {
      setMessage(error.message);
    } else if (paymentIntent && paymentIntent.status === 'succeeded') {
      setWaiterVisible(true);
      dispatch(clearCart());
      setMessage('Payment status: ' + paymentIntent.status);
      handleOrder();
    } else {
      setMessage('Unexpected state');
    }

    setIsProcessing(false);
  };

  return (
    <form onSubmit={handlePay} className={styles.form}>
      <PaymentElement />
      <button disabled={isProcessing} type="submit" className={styles.divForPaymentButton}>
        <span className={styles.paymentButton}>{isProcessing ? 'Processing..' : 'Pay'}</span>
      </button>
      <span className={styles.message}>{message}</span>
    </form>
  );
}

export default FormStripe;
