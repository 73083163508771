import React from 'react';
import addButtonIcon from '../images/addButtonIcon.svg';
import { URL } from '../utils/url.js';
import { useNavigate } from 'react-router';
import '../cssFolder/Menu.css';

function CategoryNameWithItems({ handleChangeCategory, items, category }) {
  const navigate = useNavigate();

  return (
    <div key={category.id} className="pizzaPart">
      <p className="categoryParentName">{category.name}</p>
      <div className="divForCategories">
        {items.slice(0, 4).map((item) => {
          return (
            <div
              key={item.id}
              className="eachCategoryContainer"
              onClick={() => navigate(`/product/${item.id}`)}>
              <div style={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}>
                <img className="ImageForCategoryPizza" src={`${URL}/${item.image}`} alt="" />
                <p className="categoryName">{item.name}</p>
                <p className="categoryPrice">{item.price} amd</p>
              </div>
              <div
                className="divForAddItemWithButton"
                onClick={() => {
                  navigate(`/product/${item.id}`);
                }}>
                <button className="itemAddButton">Add</button>
                <img src={addButtonIcon} alt="" />
              </div>
            </div>
          );
        })}
      </div>
      {items.length > 4 && (
        <button
          className="moreBtn"
          onClick={() => {
            handleChangeCategory({ name: category.name, id: category.id });
          }}>
          More
        </button>
      )}
    </div>
  );
}

export default CategoryNameWithItems;
