import geolocationIcon from '../images/geolocationIcon.svg';
import inputSearchIcon from '../images/inputSearchIcon.svg';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories } from '../redux/categoriesSlice';
import { backToAllProducts, getItems, getSearchedItems } from '../redux/itemsSlice';
import CategoryNameWithItems from './CategoryNameWithItems';
import FilteredItems from './FilteredItems';
import ParentCategory from './ParentCategory';
import Loading from './Loading';
import SearchedProducts from './SearchedProducts';
import { useParams } from 'react-router';
import '../cssFolder/Menu.css';

function Menu() {
  const dispatch = useDispatch();
  const [activeCategory, setActiveCAtegory] = useState('');
  const restInfo = useSelector((state) => state.restaurantInfo.restaurantInfo);
  const allItems = useSelector((state) => state.items.items);
  const allCategories = useSelector((state) => state.categories.categories);
  const parentCategories = allCategories.filter((category) => category.parentID === null);
  const isLoading = useSelector((state) => state.items.isLoading);
  const [searchText, setSearchText] = useState('');
  const searchedItems = useSelector((state) => state.items.searchedItems);
  const message = useSelector((state) => state.items.message);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getCategories({ id }));
      dispatch(getItems({ id, category: activeCategory }));
    }
  }, [dispatch, id, activeCategory]);

  // dynamic get all products for current category
  const getItemsForCategories = (categoryID) => {
    const result = [];
    const items = allItems.filter((item) => +item.categoryID === categoryID);

    const secondCategories = allCategories.filter((category) => +category.parentID === categoryID);
    secondCategories.forEach((secondCategory) => {
      const lastCategories = allCategories.filter(
        (category) => +category.parentID === secondCategory.id,
      );
      allItems.forEach((item) => {
        if (+item.categoryID === secondCategory.id) {
          result.push(item);
        }
      });
      lastCategories.forEach((category) => {
        allItems.forEach((item) => {
          if (+item.categoryID === category.id) {
            result.push(item);
          }
        });
      });
    });

    return [...items, ...result];
  };

  // change parent category for filter products

  const handleChangeCategory = ({ name, id }) => {
    setActiveCAtegory((state) => {
      if (state.id === id) {
        return '';
      } else {
        return { name, id };
      }
    });
  };

  // search products
  const handleSearchProducts = (e) => {
    e.preventDefault();
    dispatch(getSearchedItems({ id: restInfo.id, searchText }));
  };

  // back to all products
  const backToAllProductsHandle = () => {
    dispatch(backToAllProducts());
  };

  // loading
  if (isLoading) {
    return (
      <div className="loadingWrapper">
        <Loading />
      </div>
    );
  }

  return (
    <div className="templateOne">
      {/* geo image and text */}
      <div className="glctWithDscpt">
        <div className="geoLocationWrapper">
          <div className="divForGeolocationIcon">
            <img src={geolocationIcon} alt="geo" />
          </div>
          <p className="streetAddress">{restInfo?.address}</p>
        </div>
      </div>

      {/* search product form */}
      <form className="searchInputWithButton" onSubmit={(e) => handleSearchProducts(e)}>
        <input
          className="foodSearchInput"
          type="text"
          placeholder="Search"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <button type="submit" className="inputSearchIcon">
          <img src={inputSearchIcon} className="search_input_img" alt="search" />
        </button>
      </form>

      {/* Parent Categories for filter */}
      {!message && !searchedItems.length && (
        <div className="allCategories">
          {parentCategories.map((category) => {
            return (
              <ParentCategory
                key={category.id}
                category={category}
                handleChangeCategory={handleChangeCategory}
                activeCategory={activeCategory}
              />
            );
          })}
        </div>
      )}

      {/* products */}
      <div className="contentContainer">
        {/* 0 searched products and message for him */}
        {message && (
          <div className="message_wrapper">
            <button className="backToAllProductsBtn" onClick={backToAllProductsHandle}>
              Back to all Products
            </button>
            <span className="message">{message}</span>
          </div>
        )}

        {/* searched products */}
        {!message && !!searchedItems.length && (
          <div className="searchedProductsWrapper">
            <button className="backToAllProductsBtn" onClick={backToAllProductsHandle}>
              Back to all Products
            </button>
            <SearchedProducts searchedItems={searchedItems} />
          </div>
        )}

        {/* filtered products by parent category */}
        {!message && !searchedItems.length && activeCategory.id && (
          <FilteredItems
            getItemsForCategories={getItemsForCategories}
            categoryID={activeCategory.id}
          />
        )}

        {/* category name with 4 products */}
        {!message &&
          !searchedItems.length &&
          !activeCategory.id &&
          parentCategories.map((category) => {
            const items = getItemsForCategories(category.id);
            return items.length ? (
              <CategoryNameWithItems
                handleChangeCategory={handleChangeCategory}
                key={category.id}
                items={items}
                category={category}
              />
            ) : null;
          })}
      </div>
    </div>
  );
}

export default Menu;
