import { useLocation, useNavigate } from 'react-router';
import checkoutStyles from '../cssFolder/Checkout.module.css';
import goBackIcon from '../images/goBackIcon.svg';
import { useEffect, useState } from 'react';
import paymentWaiter from '../images/paymentWaiter.svg';
import { useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { URL } from '../utils/url';
import FormStripe from './FormStripe';

function Checkout() {
  const [waiterVisible, setWaiterVisible] = useState(false);
  const navigate = useNavigate();
  const products = useSelector((state) => state.cart.products);
  const totalPrice = products
    .filter((product) => product.status === 'active')
    .reduce((acc, product) => acc + +product.price * +product.count, 0);
  const servicePrice = 100;

  // change active page in footer
  const { pathname } = useLocation();
  localStorage.setItem('activePage', pathname);

  // stripe

  const [clientSecret, setClientSecret] = useState('');
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    // get Publishable Key

    fetch(`${URL}/stripe/get-publishable-key`)
      .then((res) => res.json())
      .then((data) => {
        const { publishableKey } = data;
        setStripePromise(loadStripe(publishableKey));
      });

    // get Client Secret

    fetch(`${URL}/stripe/create-payment-intent`, {
      method: 'POST',
      body: JSON.stringify({ total: totalPrice + servicePrice }),
      headers: {
        'Content-type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((payment) => {
        console.log(payment.id);
        localStorage.setItem('paymentIntentId', payment?.id || '');
        setClientSecret(payment.client_secret);
      });
  }, [totalPrice]);

  return (
    <div
      className={waiterVisible === false ? checkoutStyles.payment : checkoutStyles.paymentActive}>
      <div className={checkoutStyles.divForGoBackIconStyle}>
        <img onClick={() => navigate(-1)} src={goBackIcon} alt="goback" />
      </div>

      <div
        className={
          waiterVisible === false
            ? checkoutStyles.paymentCheckout
            : checkoutStyles.paymentCheckoutActive
        }>
        <p className={checkoutStyles.paymentName}>Checkout</p>
        <div className={checkoutStyles.orderServiceTotalDiv}>
          <div className={checkoutStyles.checkoutNamesWithPrices}>
            <p className={checkoutStyles.orderName}>Order</p>
            <p className={checkoutStyles.orderPrice}>{totalPrice} amd</p>
          </div>
          <div className={checkoutStyles.checkoutNamesWithPrices}>
            <p className={checkoutStyles.serviceName}>Service</p>
            <p className={checkoutStyles.servicePrice}>{servicePrice} amd</p>
          </div>
          <hr />
          <div className={checkoutStyles.checkoutNamesWithPrices}>
            <p className={checkoutStyles.totalName}>Total</p>
            <p className={checkoutStyles.totalPrice}>{totalPrice + servicePrice} amd</p>
          </div>
        </div>
      </div>

      {!waiterVisible && (
        <div className={checkoutStyles.stripe_wrapper}>
          {stripePromise && clientSecret && (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <FormStripe setWaiterVisible={setWaiterVisible} amount={totalPrice + servicePrice} />
            </Elements>
          )}
        </div>
      )}

      <div
        className={
          waiterVisible === false
            ? checkoutStyles.animationOfWaiterComing
            : checkoutStyles.animationOfWaiterComingActive
        }>
        <img src={paymentWaiter} alt="" />
        <div className={checkoutStyles.payment_waiter_wrapper}>
          <p className={checkoutStyles.paymentWaiterText}>The waiter is </p>
          <p className={checkoutStyles.paymentWaiterText}>bringing the bill</p>
        </div>
      </div>
    </div>
  );
}

export default Checkout;
